


































import DealerDetail from '@/components/DealerDetail.vue';
import DealerDualMenu from '@/components/DealerDualMenu.vue';
import SortableTable from '@/components/SortableTable.vue';
import {
  SortableTableDataConfig,
  SortableTableHeader
} from '@/types/SortableTableFormat';
import {
  initPagination,
  Pagination,
  updatePagination
} from '@/types/Pagination';
import {
  WarrantySealListResponse
} from '@/types/api/WarrantySeal';
import {
  initTableSortAndFilter,
  updateTableSort,
  TableSortAndFilter
} from '@/types/TableSortAndFilter';
import {
  cleanupFilterRequest,
  createFilterRequest
} from '@/types/api/FilterRequest';
import debounce from 'debounce';
import Vue from 'vue';
export default Vue.extend({
  'name': 'DealerWarrantySealList',
  'components': {
    DealerDetail,
    DealerDualMenu,
    SortableTable
  },
  data () {
    const pagination = initPagination();
    const tableFilter = initTableSortAndFilter();
    return {
      pagination,
      tableFilter,
      'tableHeader': [
        {
          'key': '',
          'display': '',
          'width': 500,
          'cssClass': '',
          'isSortable': false,
          'sortOrder': 0,
          'isEmpty': true
        },
        {
          'key': 'runNo',
          'display': 'Running No.',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'qty',
          'display': 'Warranty Seal Qty',
          'width': 250,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'bulkWarrantySeal.createdAt',
          'display': 'Delivery Date',
          'width': 250,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': '',
          'display': '',
          'width': 500,
          'cssClass': '',
          'isSortable': false,
          'sortOrder': 0,
          'isEmpty': true
        }
      ] as Array<SortableTableHeader>,
      'tableDataConfigs': [
        {
          'cssClass': 'text-center',
          'hasDisplay': false
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'isDate': true,
          'dateTimeFormat': 'DD MMM YYYY'
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': false
        }
      ] as Array<SortableTableDataConfig>,
      'tableData': [] as unknown,
      'debounceGetList': null as unknown
    };
  },
  'watch': {
    'tableFilter.search' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    },
    'pagination.page' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    }
  },
  mounted () {
    this.debounceGetList = debounce(this.getList, 500);

    this.getList();
  },
  'methods': {
    getList () {
      let getRequest = createFilterRequest(this.pagination, this.tableFilter);
      getRequest = cleanupFilterRequest(getRequest);

      this.axios
        .get('warranty-seal/list', {
          'params': getRequest
        })
        .then(({ data }) => {
          const response = data as WarrantySealListResponse;
          this.tableData = response.result.items;

          const paginationUpdate = response.result as Pagination;
          updatePagination(this.pagination, paginationUpdate);
        });
    },
    updateTableSort (tableSort: TableSortAndFilter) {
      updateTableSort(this.tableFilter, tableSort);
      this.getList();
    }
  }
});
